/* The above code is a React component named `Newusers` that manages user transactions and KYC (Know
Your Customer) details. Here is a summary of what the code does: */
import React, { useEffect, useState } from 'react';
import Navbar from '../../common/Navbar';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { db } from '../../firebase.config';
import { query, getDocs, collection, where, addDoc, updateDoc, deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { LuBox } from "react-icons/lu";

function Newusers() {
    const [transactions, setTransactions] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [kycDetails, setKycDetails] = useState({
        accountNumber: '',
        accountType: '',
        bankName: '',
        billingAddress: '',
        cityState: '',
        companyEmail: '',
        companyName: '',
        companyPhone: '',
        companyType: '',
        gstNumber: '',
        ifscCode: '',
        kycStatus: '',
        ownerName: '',
        pinCode: '',
        timestamp: new Date(),
    });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchTransactions = async () => {
        try {
            const q1 = query(collection(db, "kycdetails"));
            const querySnapshot1 = await getDocs(q1);
            let allkycusers = [];
            querySnapshot1.forEach((doc) => {
                allkycusers.push(doc.id);
            });
            console.log("All kyc users", allkycusers);

            const q = query(collection(db, "users"));
            const querySnapshot = await getDocs(q);
            let orders = [];
            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                orders.push({ ...docData, id: doc.id, kycExist: allkycusers.includes(doc.id) });
            });
            setTransactions(orders);
            console.log(orders);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, []);

    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [paginatedTransactions, setPaginatedTransactions] = useState([]);

    useEffect(() => {
        const sortAndPaginate = () => {
            const sorted = filteredList;
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize, sorted.length);
            setPaginatedTransactions(sorted.slice(startIndex, endIndex));
        };
        sortAndPaginate();
    }, [filteredList, currentPage, pageSize]);

    useEffect(() => {
        const filteredOrders = transactions.filter((item) => {
            // Search by User ID (exact match)
            if (searchQuery && !item.id.includes(searchQuery)) {
                return false;
            }

            // Filter by date range if provided
            if (startDate && endDate) {
                const itemMoment = moment(item.created_at?.toDate());
                if (!itemMoment.isValid()) {
                    return false;
                }
                const startDateMoment = moment(startDate, 'YYYY-MM-DD');
                const endDateMoment = moment(endDate, 'YYYY-MM-DD');
                if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
                    return false;
                }
                return itemMoment >= startDateMoment && itemMoment <= endDateMoment;
            }

            return true;
        });

        setFilteredList(filteredOrders);
    }, [transactions, searchQuery, startDate, endDate]);


    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < Math.ceil(transactions.length / pageSize)) {
            setCurrentPage(nextPage => nextPage + 1);
        }
    };

    const openModal = async (userId) => {
        setSelectedUserId(userId);

        // Fetch KYC details for the selected user if they exist
        const kycRef = doc(db, "kycdetails", userId);
        const docSnapshot = await getDoc(kycRef);

        if (docSnapshot.exists()) {
            // Set the existing KYC details in state
            setKycDetails(docSnapshot.data());
        } else {
            // No existing KYC details, use default values
            setKycDetails({
                accountNumber: '',
                accountType: '',
                bankName: '',
                billingAddress: '',
                cityState: '',
                companyEmail: '',
                companyName: '',
                companyPhone: '',
                companyType: '',
                gstNumber: '',
                ifscCode: '',
                kycStatus: '',
                ownerName: '',
                pinCode: '',
                timestamp: new Date(),
            });
        }

        setIsModalOpen(true);
    };

    // Close modal and reset the form to default values
    const closeModal = () => {
        setIsModalOpen(false);
        setKycDetails({
            accountNumber: '',
            accountType: '',
            bankName: '',
            billingAddress: '',
            cityState: '',
            companyEmail: '',
            companyName: '',
            companyPhone: '',
            companyType: '',
            gstNumber: '',
            ifscCode: '',
            kycStatus: '',
            ownerName: '',
            pinCode: '',
            timestamp: new Date(),
        });
    };


    const handleChange = (e) => {
        setKycDetails({
            ...kycDetails,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async () => {
        try {
            if (selectedUserId) {
                const kycRef = doc(db, "kycdetails", selectedUserId);

                // Check if the document exists
                const docSnapshot = await getDoc(kycRef);

                if (docSnapshot.exists()) {
                    // Document exists, update it
                    await updateDoc(kycRef, kycDetails);
                    console.log("KYC details updated successfully");
                } else {
                    // Document does not exist, create a new one
                    await setDoc(kycRef, {
                        ...kycDetails,
                        userId: selectedUserId,
                        createdAt: new Date(), // Optionally add timestamp
                    });
                    console.log("KYC details added successfully");
                }
            } else {
                console.error("No selected user ID provided");
            }

            closeModal(); // Close modal after success
            fetchTransactions(); // Fetch updated data
        } catch (error) {
            console.error("Error saving KYC details:", error);
        }
    };


    const handleDelete = async () => {
        try {
            if (selectedUserId) {
                const kycRef = doc(db, "kycdetails", selectedUserId);
                await deleteDoc(kycRef);
                closeModal();
                fetchTransactions();
            }
        } catch (error) {
            console.error("Error deleting KYC details:", error);
        }
    };

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value); // Update search query with user input
    };

    return (
        <div>
            <Navbar />

            <div className="mt-5 relative overflow-x-auto shadow-md sm:rounded-lg">

                <form className="flex items-center max-w-sm mx-full" onSubmit={(e) => e.preventDefault()}>
                    <label htmlFor="simple-search" className="sr-only">Search</label>
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <LuBox size={18} />
                        </div>
                        <input
                            type="text"
                            id="simple-search"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search by User ID"
                            value={searchQuery}
                            onChange={handleSearchInputChange} // Update search query on change
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                        <span className="sr-only">Search</span>
                    </button>
                </form>


                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Name</th>
                            <th scope="col" className="px-6 py-3">Email</th>
                            <th scope="col" className="px-6 py-3">Phone Number</th>
                            <th scope="col" className="px-6 py-3">KYC STATUS</th>
                            <th scope="col" className="px-6 py-3">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedTransactions.map((item) => (
                            <tr key={item.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {item.name}
                                </th>
                                <td className="px-6 py-4">{item.email}</td>
                                <td className="px-6 py-4">{item.phone}</td>
                                <td className={item.kycExist === false ? "px-6 py-4 text-red-500" : "px-6 py-4 text-green-500"}>
                                    {item.kycExist === false ? "Not Completed" : "Completed"}
                                </td>
                                <td className="px-6 py-4">
                                    <button onClick={() => openModal(item.id)} style={{ color: 'white' }} className="btn">Edit</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <nav className="bg-gray-800 rounded-lg p-2 py-2 mt-2 flex items-center flex-column flex-wrap md:flex-row justify-between" aria-label="Table navigation">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                    Showing <span className="font-semibold text-gray-900 dark:text-white">1-{transactions.length < pageSize ? transactions.length : pageSize}</span> of <span className="font-semibold text-gray-900 dark:text-white">{transactions.length}</span>
                </span>
                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                    <li>
                        <a onClick={handlePrevClick} id="prevButton" className="flex cursor-pointer items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:opacity-50 disabled:cursor-not-allowed">Previous</a>
                    </li>
                    <li>
                        <a onClick={handleNextClick} id="nextButton" className="flex cursor-pointer items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:opacity-50 disabled:cursor-not-allowed">Next</a>
                    </li>
                </ul>
            </nav>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-black opacity-25"></div>
                    <div className="bg-white w-2/3 rounded-lg p-6 z-10">
                        <h2 className="text-2xl mb-4">Edit/Add KYC Details</h2>

                        {/* Grid layout for input fields with 5 inputs per column */}
                        <div className="grid grid-cols-3 gap-4">
                            {/* Column 1 */}
                            <div className="flex flex-col space-y-4">
                                <input
                                    type="text"
                                    name="accountNumber"
                                    value={kycDetails.accountNumber}
                                    onChange={handleChange}
                                    placeholder="Account Number"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    name="accountType"
                                    value={kycDetails.accountType}
                                    onChange={handleChange}
                                    placeholder="Account Type"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    name="bankName"
                                    value={kycDetails.bankName}
                                    onChange={handleChange}
                                    placeholder="Bank Name"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    name="billingAddress"
                                    value={kycDetails.billingAddress}
                                    onChange={handleChange}
                                    placeholder="Billing Address"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    name="cityState"
                                    value={kycDetails.cityState}
                                    onChange={handleChange}
                                    placeholder="City State"
                                    className="form-input"
                                />
                            </div>

                            {/* Column 2 */}
                            <div className="flex flex-col space-y-4">
                                <input
                                    type="text"
                                    name="companyEmail"
                                    value={kycDetails.companyEmail}
                                    onChange={handleChange}
                                    placeholder="Company Email"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    name="companyName"
                                    value={kycDetails.companyName}
                                    onChange={handleChange}
                                    placeholder="Company Name"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    name="companyPhone"
                                    value={kycDetails.companyPhone}
                                    onChange={handleChange}
                                    placeholder="Company Phone"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    name="companyType"
                                    value={kycDetails.companyType}
                                    onChange={handleChange}
                                    placeholder="Company Type"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    name="gstNumber"
                                    value={kycDetails.gstNumber}
                                    onChange={handleChange}
                                    placeholder="GST Number"
                                    className="form-input"
                                />
                            </div>

                            {/* Column 3 */}
                            <div className="flex flex-col space-y-4">
                                <input
                                    type="text"
                                    name="ifscCode"
                                    value={kycDetails.ifscCode}
                                    onChange={handleChange}
                                    placeholder="IFSC Code"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    name="kycStatus"
                                    value={kycDetails.kycStatus}
                                    onChange={handleChange}
                                    placeholder="KYC Status"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    name="ownerName"
                                    value={kycDetails.ownerName}
                                    onChange={handleChange}
                                    placeholder="Owner Name"
                                    className="form-input"
                                />
                                <input
                                    type="text"
                                    name="pinCode"
                                    value={kycDetails.pinCode}
                                    onChange={handleChange}
                                    placeholder="Pincode"
                                    className="form-input"
                                />
                            </div>
                        </div>

                        <div className="mt-4 flex justify-end space-x-4">
                            <button onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded-lg">Submit</button>
                            <button onClick={closeModal} className="bg-gray-500 text-white px-4 py-2 rounded-lg">Close</button>
                            {selectedUserId && (
                                <button onClick={handleDelete} className="bg-red-500 text-white px-4 py-2 rounded-lg">Delete</button>
                            )}
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
}

export default Newusers;
